.variance-dropdown {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ui.dropdown.dropdown-text > .text{
  display: ruby-base-container !important;
}

.variance-cell-wrapper{
  width: 100%;
  min-width: 100%;
  height: 35px;
  padding: 0em 0.5em;
}

.dropdown-var{
  margin-bottom: 10%;
  margin-left: 5%;
  color: white;
  font-weight: bold;
  font-size: small;
}