.rules-toggle {
    margin-left: 15em !important;
}

.ui.floating.dropdown.subgroup {
    max-width: 15em !important;
    width: 20em !important;
}

.ui.floating.dropdown.menu.subgroup {
    max-width: 15em !important;
}

.menu.transition.visible.subgroup {
    max-width: 25em !important;
}

.ui.button.dropdown-filters {
    margin-inline: 1%;
    width: 48%;
}

.filter-tittle {
    margin-top: 1em;
    font-size: 120%;
}

.list-searchbar {
    margin-top: 1em;
    width: 100%;

}

.scrollable-list {
    max-height: 18.5em !important;
    overflow-y: auto;
    width: 100%;
}