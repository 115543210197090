.analytics-filter-time > .visible.menu.transition > .active.selected.item{
    font-weight: lighter;
    background-color: none;
}

/* Remove all extra padding/margins from grid containers */
/* .ui.grid {
    margin: 0 !important;
}

.ui.grid > .row {
    padding: 0.25rem 0 !important;
}

.ui.grid > .column:not(.row),
.ui.grid > .row > .column {
    padding: 0.5rem !important;
} */

/* Label and field styling */
.filter-group .ui.form .field > label {
    margin-bottom: 0.5rem !important;
    display: block !important;
}

/* Simple filter group styling */
.filter-group {
    margin-bottom: 0.25rem !important;
}

.filter-row .no-p-top {
    padding-top: 0 !important;
}

.filter-row .ui.selection.dropdown {
    min-width: 18rem;
}

@media only screen and (max-width: 1400px) {
    .filter-row .ui.selection.dropdown {
        min-width: 14rem;
    }
}

@media only screen and (max-width: 900px) {
    .filter-row .ui.selection.dropdown {
        min-width: 11rem;
    }
}

/* Consistent input styling */
.filter-group .ui.dropdown {
    width: 100% !important;
}

/* Ensure date inputs match dropdowns */
input[type="date"].date-input {
    height: 38px !important;
    box-sizing: border-box !important;
}

/* For better alignment with other form elements */
.ui.form .field {
    margin-bottom: 0 !important;
}

/* Mobile responsiveness
@media only screen and (max-width: 767px) {
    .filter-group {
        padding-bottom: 0.5rem !important;
    }
} */

/* Apply button styling */
.apply-button-column {
    display: flex;
    align-items: flex-end;
    margin-bottom: 0.25rem !important;
}

.apply-button {
    margin-bottom: 0px !important;
}

/* Mobile responsiveness for apply button */
@media only screen and (max-width: 767px) {
    .apply-button-column {
        padding-top: 0.5rem !important;
    }
}

/* Collapsible filter section styles */
.filter-section-header {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
    margin-bottom: 0.5rem;
}

/* When collapsed, add some bottom padding to the header */
.filter-section-collapsed .filter-section-header {
    margin-bottom: 0;
    padding-bottom: 0.5rem;
}

/* Transition for collapsible section */
.filter-section-content {
    transition: max-height 0.3s ease-out, opacity 0.2s ease-out;
    overflow: visible;
}