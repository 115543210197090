
.ui.modal.transition.visible.active.newMasterModal{
    padding-right: 2rem;}

.ui.warning.message.masterModal{    
    margin-left: 1rem; 
    margin-right: 1rem; 
}
.ui.active.button.newMasterModalCancelButton{
    color: rgba(0, 0, 0, 0.6);    
}
.ui.active.button.newMasterModalCancelButton:hover{
    background-color: #CACBCD;
    color: rgba(0, 0, 0, 0.8);    
}
.ui.active.button.newMasterModalEnterButton{
    color: #FFFFFF;
    background-color: #B5B6E3;
}
.ui.active.button.newMasterModalEnterButton:hover{
    color: #FFFFFF;
    background-color: #a0a1df;
}