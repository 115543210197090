.progress-message {
  margin-left: 0.5em;
}

.table-upload-indicator {
  text-align: right;
  margin-right: 2em;
  font-weight: 500;
  font-size: 1.25em;
  /* float:left; */
  /* position: relative;
  top: 50%;
  transform: translateY(-50%); */
}