.ui.basic.buttons .button, .ui.basic.button{
    text-align: left !important;
}
.ui.button{
    padding: 0.6em 1.5em !important;
}

.button-text, .button-with-text{
    color: black !important;
}

.field-title{
    text-align: left;
    padding-left: 5px;
}