.ui.modal.transition.visible.active.groupWarning{
    padding-right: 2rem;}

.ui.warning.message.groupWarning{    
    margin-left: 1rem; 
    margin-right: 1rem; 
}

.ui.active.button.groupWarningContinueButtonn:hover{
    background-color: #CACBCD;
    color: rgba(0, 0, 0, 0.8);    
}
.ui.active.button.groupWarningContinueButton{
    color: #FFFFFF;
    background-color: #B5B6E3;
}