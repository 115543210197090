.ui.mini.icon.positive.button.edit-simulation-name-button-background{
    background: white;
    padding: 0px 10px !important;
    font-size: 1rem !important;
}

.ui.mini.icon.positive.button.edit-simulation-name-button-background > .grey.pencil.icon.icon-edit-simulation-name-button:hover{
    color: rgb(253, 128, 139) !important;
}

.sim-name-popup-error{
    color: rgb(161, 45, 51) !important;
}