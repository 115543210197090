.ui.modal.transition.visible.active.sideEffectsModal{
    padding-right: 2rem;}

.ui.warning.message.sideEffectsModal{    
    margin-left: 1rem; 
    margin-right: 1rem; 
}

.ui.active.button.sideEffectsModalContinueButtonn:hover{
    background-color: #CACBCD;
    color: rgba(0, 0, 0, 0.8);    
}
.ui.active.button.sideEffectsModalContinueButton{
    color: #FFFFFF;
    background-color: #B5B6E3;
}