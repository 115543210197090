.login-container {
  height: 100%;
  display: flex;
  align-content: center;
  margin: 0px;
}

.login-column {
  max-width: 450px !important;
}

.ui.center.aligned.header.login-message{
  color: rgb(113, 118, 181) !important;
}

.register-button{
  margin-top: 10% !important;
  color: rgb(113, 118, 181) !important;
  letter-spacing: 0.5px !important;
}

.ui.label.forget-password{
  margin-top: 3%;
  padding: 0px;
  background-color: transparent !important;
  color: rgb(113, 118, 181);
  letter-spacing: 0.5px;
}

.ui.label.forget-password:hover{
  cursor: pointer;
}

.ui.icon.button.actionEyeButton{
  padding: 0.1em 0.5em !important;
}