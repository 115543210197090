.formula-autocomplete-item {
    cursor: default !important;
}
.formula-input {
    position: relative;
    z-index: 2;
}
.formula-dropdown-container {
    height: 2.4em;
    margin-top: -2.8em;
    position: relative;
    z-index: 1;
}
.formula-dropdown-menu {
    width: 100%;
    max-height: 12em;
    overflow-y: auto;
}
.option-type {
    font-size: 0.8em;
}
.option-hint {
    font-family: monospace;
    font-size: 0.8em;
    color: rgb(113, 118, 181);
}
