.event-menu-container{
    width: 18.5% !important;
    margin-right: 1%;
}

.separator-calendar{
    margin-top: 15%;
} 

.ui.compact.vertical.calendar-menu-event{
    border-radius: 5px !important;
}

.title-calendar-menu{
    text-align: center !important;
}