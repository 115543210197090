.group-settings-container{
  height: 100%;
}

.groups-segment,
.rules-segment {
  padding: 2em !important;
}

label {
  display: block;
  margin: 0 0 0.3rem 0;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.93em;
  font-weight: 700;
  text-transform: none;
}

label.filters {
  margin-top: 2.5em;
}

label.dropdown {
  text-align: left !important;
}

.saved-groups,
.saved-rules {
  margin-top: 1em;
  padding: 1em;
}

.groups-segment {
  max-width:130em;
  margin:0px auto !important;
}
.subgroups-header{
  text-align: center;
  position: relative;
}

.add-button-container{
  padding-top: 10px !important;
  padding-bottom: 30px!important;
}

.group-settings-add-button-container{
  justify-content: center;
  display: flex;
  padding-bottom: 10px;
}