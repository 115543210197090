.ui.dropdown.errorsDropdown{
    font-size: 1em;
    cursor: initial;
}

.ui.basic.button.dropdown.errorsDropdown{
    background-color: rgb(237, 135, 141) !important;
    color: white !important;
    border-color: rgb(237, 135, 141) !important;
}

.ui.dropdown.errorsDropdown .menu >.item{
    font-size: 0.9em;
    cursor: initial;
}

.ui.dropdown.errorsDropdown .menu >.item :hover{
    cursor: initial;
}

.ui.dropdown.errorsDropdown .menu .selected.item{
    cursor: initial;
}

/* .errors-order-column{
    display: flex !important;
    flex-direction: row-reverse !important;
} */