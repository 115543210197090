.workspace-selector {
  /* padding-left: 0.5em !important;
  padding-right: 2.5em !important; */
  /* padding-bottom: 1em !important; */
  width: 100%;
}

.menu_box{
  box-sizing: content-box;
}

.workspace-selector-label {
  font-size: 1rem;
  margin-bottom: 1rem;
  margin-top: 1.2rem;
  text-align: left;
  padding-left: 0.5rem;
}

