.token-container {
    width: 20 cm !important;
  }
  .ui.button.revokeTokenButton {
    /* margin-left: 0.5em !important; */
    background-color: #ec5732;
    color: #FFFFFF;
    margin-top: 1em;
    margin-bottom: 1em;
  }
  
  /* .create-workspace-form-buttons {
    text-align: right !important;
  }
  
  .create-workspace-container {
    text-align: left !important;
  }
  
  .create-workspace-header {
    margin-bottom: 1.5em !important;
  }
  
  .workspace-row-left-button {
    margin-right: 0.5em !important;
    padding: 0.5em;
  } */