/* Simulation Card */
.ui.card.simulation-card{
    width: 100% !important;
}

.simulation-card-header{
    background-color: rgb(168,169, 213) !important;
    display: grid;
    grid-template-columns: 35px 35px 35px 1fr;
    gap: 0em 4em;
}

.simulate-label-title{
    font-weight: bold;
    color: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.simulation-card-header-title{
    display: flex;
    color: white !important;
    cursor: pointer;
    letter-spacing: 0.5px;
    font-size: 1em;
}

.ui.card > .content.simulation-card-header{
    padding: 0.6em 1.8em !important;
}

.simulate-label-title-add-info{
    padding-left: 20px;
}
