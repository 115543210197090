.details-top-section,
.details-bottom-section {
  width: 100% !important;
}

.details-bottom-section {
  font-size: 0.89em;
}

.help {
  float: left;
}

.empty-state {
  opacity: 0.6;
}

div.close-details {
  justify-content: right !important;
  margin-bottom: -1.5em !important;
}

.details-top-section .card {
  box-shadow: 0px 0px 0px 0px #D4D4D5, 0px 0px 0px 0px #D4D4D5 !important;
}