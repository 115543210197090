.ui.input.adu-measurement {
  margin-top: 2.5em !important;
  margin-bottom: 0.5em !important;
}

.slider-wrapper {
  margin: 10%;
  width: 80%;
}

.ui.grid>.column:not(.row).column.thin-column {
  padding-right: 0em;
}

.disabled-slider {
  margin: 10%;
  width: 80%;
  opacity: 0.45 !important;
}