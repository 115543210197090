
@keyframes fillBar {
  0% {
    transform: scaleY(0.5);
  }
  60% {
    transform: scaleY(1.05);
  }
  100% {
    transform: scaleY(1);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0.1;
  }
}
@keyframes slideIn {
  0% {
    transform: scaleX(0);
    opacity: 0;
  }
  80% {
    opacity: 0.2;
  }
}

.topOfZone {
  animation: fadeIn 0.3s ease-in;
}

.topOfZoneLine {
  animation: slideIn 0.35s;
  stroke: black;
  stroke-width: 1.5px;
  stroke-dasharray: 3;
}

.zoneValue {
  font-size: 1.2em;
  font-weight: bold;
  fill: white;
  text-shadow: -1.5px 1px rgb(111, 111, 111);
}

.extraMetricsLabel {
  animation: fadeIn 0.4s ease-in;
  border: 1px solid black;
  background: black;
}

.extraMetricsLine {
  animation: slideIn 0.35s;
  stroke: black;
  stroke-width: 1.5px;
  stroke-dasharray: 3;
}

.chart-popup-content {
  text-align: center !important;
}

