.triangle-marker,
.circle-marker {
  animation: fadeIn 0.2s ease-in;
  fill: white;
  stroke: grey;
  stroke-width: 2;
}

.svg-buffer-details-chart {
  height: 430px !important;
  min-height: 440px !important;
  max-height: 440px !important;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
