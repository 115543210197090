.event-type {
    margin-top: 1.5em;
    margin-bottom: 2em;
}
  
.per-day-radio {
  margin-top: 1.5em;
}

.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ui.modal,
.ui.active.modal {
  margin: 0 auto !important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease 0.5s;
}

.button-color{
  background-color: rgb(168, 169, 217) !important;
  color: white !important;
}

.button-color:hover{
  background-color: rgb(130, 132, 223) !important;
}

/* Add Button */
.add-button{
  background-color: rgb(81, 177, 164) !important;
  color: white !important;
  width: 20% !important;
}