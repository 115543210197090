.button-color{
    background-color: rgb(168, 169, 217) !important;
    color: white !important;
}

.button-color:hover{
    background-color: rgb(130, 132, 223) !important;
}

.adu-length-settings{
    padding-left: 1% !important;
    padding-right: 0.5% !important;
    padding-bottom: 3% !important;
    text-align: center;
}
