.top-nav {
  font-size: 1.25rem !important;
  margin-top: 0 !important;
}
.top-nav-dropdown {
  font-size: 1rem !important;
}
.icon.large {
  font-size: 2.5rem !important;
}
.account-popup {
  text-align: left;
  padding: 0.5em !important;
}
.link-no-style {
  color: inherit !important;
}
.item.settings-icon {
  margin-top: 2.5em !important;
}
.item.settings-icon i {
  margin-left: -1em !important;
}
.item.settings-icon-moved i {
  margin-top: 0.5em !important;
}
.item.settings-icon-moved i {
  margin-left: -1em !important;
}
div.offline-label {
  margin-left: -2em !important;
  margin-right: auto !important;
}
.no-padding-top {
  /* padding-top: 0px; */
  padding-bottom: 0px !important;
}
.no-padding-right {
  padding-right: 0px !important;
}
.settings-logo-menu a:hover {
  background-color: transparent !important;
}
.top-nav {
  border-radius: 0px !important;
}
.svg-logo {
  max-width: 2.4em !important;
  margin-right: 0.75rem !important;
  margin-left: 0.75rem !important;
}