.register-container {
  margin-top: 2em !important;
}

.register-column {
  max-width: 450px !important;
  text-align: left !important;
}

.bottom-message {
  text-align: center !important;
}

.policy-label {
  width: unset;
  margin: 4px 6px 0 0;
}
.formFeildWrapper {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  flex-direction: row-reverse;
}

.formFeildWrapper .ui.input {
  width: auto !important;
  margin: 4px 0;
}
