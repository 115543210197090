.password-recovery-container {
  margin-top: 2em !important;
  margin-bottom: 2em !important;
}

.password-recovery-column {
  max-width: 450px !important;
  text-align: left !important;
}

.error-page-message{
  align-items: center;
  justify-content: center;
}

.send-changes-button{
  margin-top: 10% !important;
  color: rgb(113, 118, 181) !important;
  letter-spacing: 0.5px !important;
}

.account-recovery-message {
  color: rgb(113, 118, 181) !important;
}

.input-label-password-recovery > label{
  color: rgb(113, 118, 181) !important;
}

.error_screen{
  height: 100%;
  align-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(113, 118, 181);
}