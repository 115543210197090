.title {
  text-align: left !important;
  padding: 1em 0 !important;
}
.pane {
  min-height: 300px;
}

.admin-console-container {
  margin-bottom: 20px !important;
  padding-top: 4em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  min-height: 90%;
  margin-top: 1em !important;
}
