.orders-table {
  min-height: 160%;
  padding-top: 4% !important;
}

.rightAlign {
  display: flex;
  justify-content: right;
}

.commit-undo-bar button {
  float: right;
  margin-right: 1em;
}

.order-table-filters {
  padding: 0em 1em !important;
}

.layout-control-buttons .icon {
  font-size: 1.5em !important;
}

.layout-control-buttons .ui.button {
  padding-top:  0.9em !important;
  padding-bottom: 0.9em !important;
  padding-left:  0.9em !important;
  padding-right:  0.9em !important;
}

.layout-control-buttons .ui.buttons .button:first-child {
  padding-left: 1.5em !important;
}

.search-bar{
  padding-left: 1em;
  padding-right: 31em;
  padding-top: 1em;
  position:absolute;
  width: 100%;
}

/* Properties from new design */
.order-table-interactive-buttons{
  display: flex;
  align-items: center;
}

.search-bar-input-column{
  margin-left: 0.5%;
  height: min-content;
}

.saved-layout-data{
  display: flex;
  justify-content: space-between;
}
.row-errors-order{
  padding-top: 0px !important;
}

.errors-order{
  display: flex !important;
  flex-direction: row-reverse !important;
}

.ui.floating.dropdown > .menu {
  margin-top: 1.1em !important;
}