.analytics-menu-container{
    /* height: auto !important; */
    background-color: rgb(245, 246, 250) !important;
}

.analytics-height{
    height: 100% !important;
}

.simulation-height{
    height: auto !important;
}

.sub-menu-container{
    width: 31.5%;
    margin-left: 3%;
}

.ui.menu{
    border-radius: 0px !important;
}

.ui.menu > .item:first-child, .ui.compact.menu .item:last-child{
    border-radius: 0px !important;
}

.ui.menu .active.item{
    background-color: white !important;
    border-bottom: 5px #FD808B solid !important;
}

.placeholder-container{
    align-items: center !important;
}

div > .ui.secondary.segment{
    color: black !important;
}
