.data-import {
  margin-bottom: 20px !important;
  padding-top: 4em !important;
  padding-left: 3em !important;
  padding-right: 3em !important;
  min-height: 90%;
  margin-top: 1em !important;
}

.upload-buttons {
  text-align: left;
  padding: 2em !important;
}

.secondary {
  font-size: 0.88em;
}
