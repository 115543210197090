.ui.icon.button.order-column-button{
    padding-left: 10px !important;
    padding-right: 10px !important;
    background-color: rgb(143, 145, 200);
    color: white;
}

.layout-write-icon:hover{
    color: rgb(113, 118, 211)
  }
  
  
  .layout-trash-icon:hover{
    color: rgb(217, 117, 89)
  }