.on-hand-stock-summary-item {
  text-align: right !important;
}

.on-hand-stock-summary-total {
  text-align: center !important;
}

.filter.small.icon.middle.aligned.filter-icon:hover{
  cursor: pointer;
  color: rgb(41, 92, 212) !important;
}