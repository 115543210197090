.dashboard {
  padding: 0em 1.7em !important;
}

.nine,
.ten,
.eleven,
.twelve {
  transition: linear 0.15s;
}

.dashboard-container-hidden {
  display: none;
}

.top-dashboard-grid {
  height:91%;
}

.container-space {
  margin-top: 3em !important;
}