td.blue_zone.css-fl9tuy{
    background-color: rgb(151, 152, 182);
    border-radius: 5px;
    color: white;
}

td.green_zone.css-fl9tuy{
    background-color: rgb(98, 193, 182);
    border-radius: 5px;
    color: white;
}

td.yellow_zone.css-fl9tuy{
    background-color: rgb(247, 216, 109);
    border-radius: 5px;
    color: white;
}

td.red_zone.css-fl9tuy{
    background-color: rgb(217, 117, 89);
    border-radius: 5px;
    color: white;
}

td.grey_zone.css-fl9tuy{
    background-color: rgb(193, 193, 193);
    border-radius: 5px;
    color: white;
}

td.no_zone.css-fl9tuy{
    background-color: black;
    border-radius: 5px;
    color: white;
}
