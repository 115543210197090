.ui.selection.dropdown{
    padding-right: 50px !important;
    margin-bottom: 20px !important;
}

.ui.input > input{
    padding: 0.5em 1em !important;
}

/* Used in adu frequency and  quantity modifier */
.segment-title{
    text-align: left;
    padding-bottom: 15px;
}

.overall-segment-title{
    text-align: left;
    margin-bottom: -10px;
    margin-left: 20px;
}

.simulate-label{
    margin-left: 18%;
    margin-bottom: 0px;
}

.separator{
    margin-bottom: 20px;
}

/* Save changes button */
.save-changes-button{
    background-color: #A8A9D5 !important;
    color: white !important;
}