.workspace-editor {
  padding: 2em !important;
}

.create-workspace-form-buttons {
  text-align: right !important;
}

.create-workspace-container {
  text-align: left !important;
}

.create-workspace-header {
  margin-bottom: 1.5em !important;
}

.workspace-row-left-button {
  margin-right: 0.5em !important;
  padding: 0.5em;
}
.org-code-container{
  margin-left: 1.6em !important;
}
