.ui.icon.button.subgroup-buttons:hover{
    background-color: #a0a1df;
    color: #fff;
    opacity: 1;  
}

.subgroup-buttons-add{
    background-color: #a0a1df !important;
    color: #fff !important;
    opacity: 0.7 !important;
    margin-left: 41% !important;  
}

.subgroup-buttons-add:hover{
    background-color: #a0a1df !important;
    color: #fff !important;
    opacity: 1 !important;  
}

.ui.icon.button.subgroup-buttons{
    background-color: #a0a1df;
    color: #fff;  
    opacity: 0.7;
}

.ui.button.subgroup-buttons:hover{
    background-color: #a0a1df;
    color: #fff;
    opacity: 1;  
}
.ui.button.subgroup-buttons{
    background-color: #a0a1df;
    color: #fff;  
    opacity: 0.7;
}