.account-recovery-image{
    height: 100%;
    background-image: url('account-recovery.svg') !important;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
}

.account-recovery-container{
    width: 50% !important;
}

.first-header{
    color: rgb(114, 118, 177) !important;
}

.second-header{
    color: rgb(181, 182, 223) !important;
}

.account-recovery-input-label{
    color: rgb(185, 187, 216) !important;
}

.account-recovery-submit-button{
    background-color: rgb(181, 182, 223)  !important;
    color: white !important;
}

.account-recovery-submit-button:hover{
    background-color: rgb(114, 118, 177) !important;
}

