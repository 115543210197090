.group-props {
  margin-top: 2em !important;
  text-align: left !important;
}

.group-card-footer {
  float: right;
}

.event-group-exists{
  color: mediumspringgreen;
}

.no-event-group{
  color: aliceblue ;
}