.order-card-meta {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  color: black !important;
}

.order-card-header {
  font-size: 1.2em !important;
}

.card-order-recommended-qty {
  font-weight: bold;
  font-size: 1.2em !important;
}
