.ui.two.column.grid.simulation-name-container{
    margin: 0px;
}

.ui.icon.left.labeled.button.save-simulation-button{
    padding-left: 30px !important;
    background-color: transparent;
    color: white;
}

.ui.icon.left.labeled.button.save-simulation-button > i {
    background-color: transparent;
    width: 2em !important;
}

.form-save-simulation{
    justify-content: center;
    display: flex;
}

.form-save-simulation-input{
    width: 50%;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    padding-top: 30px;
}

.form-save-simulation-input > label{
    padding-right: 10px;
}

.hoverRow:hover{
    cursor: pointer;
    background-color: black;
}

.error-simulation-modify{
    justify-content: center;
    display: flex;
    padding-top: 3%;
    color: rgb(237, 134, 141);
}

.simulation-name-column{
    display: flex;
}