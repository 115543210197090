.page-selector {
  margin-top: 1em;
}

.current-page-indicator {
  margin-right: 1em;
}

.go-to-page {
  margin-left: 1em;
  margin-right: 1em;
}

.page-input {
  margin-left: 0.5em;
}
