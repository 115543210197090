.edit-group {
  text-align: left !important;

}

.action-buttons {
  float: right;
}

.filters-divider {
  margin-top: 2em !important;
}

.edit-rule {
  text-align: left !important;
  min-height: 100% !important;
  padding-left: 1em !important;
  padding-right: 1em !important;
}

.action-button {
  position: absolute;
  right: 0;
}

.group-name-input {
  padding-right: 5em !important;
}

.slider-value {
  margin-bottom: 1em !important;
}

.method-dropdown {
  margin-bottom: 2em !important;
}

.method-field {
  text-align: center !important;
  padding-left: 1em !important;
  padding-right: 3em !important;
}

.adu-length {
  text-align: center !important;
  padding-left: 1em !important;
  padding-right: 3em !important;
  margin-bottom: 3em !important;
}

.submit-group {
  margin-left: 1em !important;
}

.lead-time-slider,
.variability-slider {
  text-align: center !important;
}

.lead-time-controls,
.variability-controls {
  margin-top: 2.5em !important;
}

/* Override for lhs actions */
.lead-time-controls .button:first-child,
.variability-controls .button:first-child {
  border-radius: 0.5rem 0px 0px 0.5rem;
}

.lead-time-controls>input,
.variability-controls>input {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border-left-color: transparent !important
}


.name-filters,
.rules-group {
  padding-left: 3em !important;
  padding-right: 3em !important;
  padding-bottom: 1em !important;
  padding-top: 2.5em !important;
}

.rules-groups-disabled {
  padding-left: 3em !important;
  padding-right: 3em !important;
  padding-bottom: 1em !important;
  padding-top: 2.5em !important;
}

.rules-title,
.name-filters .top-labels label {
  font-size: 120% !important;
}

.rules-title {
  margin-bottom: 2em !important;
}

.name-filters label,
.rules-group label {
  margin-left: 0.4em !important;
}

.method-field label {
  text-align: left !important;
}


.group-filters-container {
  display: grid !important;
  grid-template-columns: repeat(auto-fill, minmax(40%, 50%)) !important;
  grid-template-rows: repeat(auto-fill, 1fr) !important;
}

.stretched.four.wide.column.group-field-dropdown {
  width: 100% !important;
}

.temporal-container-simple {
  padding: 0px !important;
}

.dropdown-group-fields {
  width: 220px;
  overflow: hidden;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
}