.filter-row{
    display: flex;
}
.time-filter-container{
    padding-left: 5%;
}

/* Styles for collapsible filter section */
.filter-section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0;
    cursor: pointer;
}

.filter-section-title {
    margin: 0;
    font-weight: 500;
    font-size: 1rem;
}

.filter-count {
    margin-left: 0.5rem;
    color: rgba(0, 0, 0, 0.6);
    font-weight: normal;
}

.toggle-filter-button {
    padding: 0.35rem !important;
    margin: 0 !important;
    box-shadow: none !important;
}

.toggle-filter-button:hover {
    background-color: rgba(0, 0, 0, 0.03) !important;
}

.toggle-filter-button .icon {
    margin: 0 !important;
}

.toggle-section-column .ui.buttons .button {
    font-size: 0.9rem !important;
}

/* Fix for dropdown menus overflowing their container */
.filter-section-content .multiple-dropdown.ui.dropdown .menu {
    overflow: auto !important;
    max-height: 300px !important;
    transform-origin: center top !important;
    transform: scaleY(1) !important;
    margin-top: 0 !important;
    top: 100% !important;
    bottom: auto !important;
    box-shadow: 0 2px 10px rgba(0,0,0,0.2) !important;
}

.filter-section-content .multiple-dropdown.ui.dropdown {
    position: relative;
}

.filter-section-content .multiple-dropdown.ui.dropdown .menu {
    position: absolute !important;
    z-index: 1000 !important;
}

/* Improve dropdown item display for better visibility */
.filter-section-content .multiple-dropdown.ui.dropdown .menu > .item {
    padding: 10px 15px !important;
    border-bottom: 1px solid rgba(0,0,0,0.05) !important;
}

/* Ensure the filter section doesn't clip its children with overflow */
.filter-section {
    overflow: visible !important;
}

.filter-section-content {
    overflow: visible !important;
    padding-bottom: 100px !important;
}