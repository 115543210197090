.edit-group {
  text-align: left !important;
  
}

.action-buttons {
  float: right;
  padding-top: 2em;
}


.action-button {
  position: absolute;
  right: 0;
}

.group-name-input {
  padding-right: 5em !important;
}


.submit-group {
  margin-left: 1em !important;
}


.name-filters {
  padding-left: 3em !important;
  padding-right: 3em !important;
  padding-bottom: 1em !important;
  padding-top: 2.5em !important;
}


.name-filters label {
  margin-left: 0.4em !important;
}

.eventgroup-header {
  text-align: center;
  margin-left: auto;
  margin-right: auto; 
}
